export class Feedback {
            constructor(
                public feedback_id: number,
                public divid: number,
                public uid: number,
                public div_name: string,
                public usr_id: string,
                public description: string,
                public resolution: string,
                public submittedDate: number,
                public status: boolean
            ) { }
       
}
