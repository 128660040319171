﻿export class PriceList {
            constructor(
                public plid: number,
                public pid: number,
                public cmpid: number,
                public pt_hsn_code: string,
                public pt_nbr: string,
                public pt_name: string,
                public pt_desc: string,
                public pt_price: number,
                public pt_prod_line: string,
                public pt_type: boolean,
                public pt_group: string,
                public pt_status: boolean,
                public pt_cgst: number,
                public pt_sgst: number,
                public pt_igst: number,
                public pt_cess: number,
                public pt_activation_date: number,
                public pt_expiry_date: number,
                public div_create_by: string,
                public div_update_by: string,
                public pt_uom: string
            ) { }
       
}
