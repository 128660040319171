﻿import { AuthData } from './../../auth/auth.model';
import { AppComponent } from './../../app.component';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Company } from './../../company/company.model';
import { User } from './../../masters/user/user.model';
import { APIURLS } from './../../shared/api-url';
import { HttpService } from './../../shared/http-service';
import * as _ from "lodash";
//declare var moment : require('moment');
declare var jQuery: any;

@Component({
  selector: 'app-gstr-upload',
  templateUrl: './gstr-upload.component.html',
  styleUrls: ['./gstr-upload.component.css']
})
export class GstrUploadComponent implements OnInit {

  
  fullMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  isLoading: boolean = false;
  file: any;
  profilePicture: any;
  errMsg: string = "";
  isLoadingPop: boolean = false;
  errMsgPop: string = "";
  years = [];
  yearDate: Date = new Date();
  
  constructor(private appService: AppComponent, private httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router, vcr: ViewContainerRef) {
  }

  ngOnInit() {
    this.getYears();
  }

  getYears()
  {
    this.years.push(this.yearDate.getFullYear());
    this.years.push(this.yearDate.getFullYear()-1);
  }

  callCancel() {
    var path = localStorage.getItem("comefrom");
    if (path != undefined && path.length > 0) {
      this.router.navigateByUrl(path);
    }
    else {
      this.router.navigateByUrl("/dashboard");
    }
  }

  FileChange(event: any) {
    this.file = event.target.files[0];

    if (event.target.files && this.file) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.profilePicture = event.target.result;
      }
      reader.readAsDataURL(this.file);
    }
    let input: FormData= new FormData();
    input.append("File", this.file);

    let connection: any;
    connection = this.httpService.post(APIURLS.BR_MASTER_FILEUPLOAD_API, input);
    connection.then((data: any) => {
      this.isLoadingPop = false;
      if (data.status == 'SUCCESS') {
        // this.toastr.success(data.message+"", 'Success!');
        // this.router.navigateByUrl('/suprinvoices');
      }
      else {
        // this.errMsgPop = data.message;
      }
    }).catch(error => {
      this.isLoadingPop = false;
      this.errMsgPop = 'Error saving file..';
    });
  }

  onFileChange(event: any) {
    
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append("File", file);
      //formData.append('uploadFile', file, file.name);
      //let headers = new Headers()
      //headers.append('Content-Type', 'json');  
      //headers.append('Accept', 'application/json');  
      //let options = new RequestOptions({ headers: headers });
      //let apiUrl1 = "/api/UploadFileApi";
      let connection: any;
      //connection = this.httpService.postforUploadFiles(APIURLS.BR_MASTER_FILEUPLOAD_API, formData);
      connection.then((data: any) => {
        this.isLoadingPop = false;
        if (data.status == 'SUCCESS') {

          // this.toastr.success(data.message+"", 'Success!');
         // this.router.navigateByUrl('/suprinvoices');
        }
        else {
         // this.errMsgPop = data.message;
        }
      }).catch(error => {
        this.isLoadingPop = false;
        this.errMsgPop = 'Error saving file ..';
      });
    }
    //window.location.reload();
  }
}  

